import { useDispatch } from "react-redux";
import { AdminTip } from "../../../types/AdminTip";
import { useCallback, useEffect, useState } from "react";
import { setAlert, setLoading } from "../../../redux/commons/action";
import { create, getById, update } from "../../../services/adminTipService";

const initValue: AdminTip = {
  text: ""
}

const useAdminTipDetail = () => {
  const dispatch = useDispatch()

  const [id, setId] = useState(0)
  const [adminTipDetail, setAdminTipDetail] = useState<AdminTip>(initValue)

  useEffect(() => {
    !!id ? getData() : setAdminTipDetail(initValue)
  }, [id])

  const reset = () => {
    setAdminTipDetail(initValue)
    setId(0)
  }

  const getData = useCallback(async () => {
    dispatch(setLoading(true))

    try {
      const res = await getById(id)
      setAdminTipDetail(res.data)
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      )
    }
    dispatch(setLoading(false))
  }, [id])

  const confirmData = useCallback(
    async (values: any, cb: any) => {
      dispatch(setLoading(true))
      try {
        !!id ? await update(values) : await create(values)
        
        dispatch(
          setAlert({
            type: "success",
            message: `${!!id ? "Update" : "Create"} admin tip successfully`,
          })
        )

        cb && cb()
        !!id && setId(0)
      } catch (err: any) {
        dispatch(
          setAlert({
            type: "danger",
            message: err.response?.data || err.message,
          })
        )
      }
      dispatch(setLoading(false))
    }, [id]
  )

  return {
    id, 
    setId,
    adminTipDetail,
    confirmData,
    initValue,
    reset,
  }
}

export default useAdminTipDetail