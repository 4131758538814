import { api } from "./api"
import { Filter } from "../types/Filter"
import { AdminTip } from "../types/AdminTip"

const ADMIN_TIP_URL = "/api/AdminTip"

export const get = (filter?: Filter) =>
  api.get(ADMIN_TIP_URL, { params: filter })

export const getById = (id: number) => api.get(`${ADMIN_TIP_URL}/${id}`)

export const create = (data: AdminTip) => api.post(ADMIN_TIP_URL, data)

export const update = (data: AdminTip) =>
  api.put(`${ADMIN_TIP_URL}/${data.id}`, data)

export const remove = (id: number) => api.delete(`${ADMIN_TIP_URL}/${id}`)