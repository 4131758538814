import React, { forwardRef } from "react"
import CommonModal from "../../../components/Modals/CommonModal"
import RequiredLabel from "../../../components/Labels/RequiredLabel"
import ErrorHandler from "../../../components/Alerts/ErrorHandler"

import * as yup from "yup"
import { Formik } from "formik"
import { Col, Row } from "reactstrap"
import { Editor } from "@tinymce/tinymce-react"
import { TINY_MCE_KEY } from "../../../utils/constants"
import { editorConfig } from "../../LegalPages/constants/constant"

const schema = yup.object({
  text: yup.string().trim().required("Tip is required")
})

const AdminTipDetailModal = (props: any, ref: any) => {
  const { header, confirmText, onConfirm, adminTipDetail, onCancel } = props
  return (
    <Formik
      initialValues={adminTipDetail}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values: AnalyserNode, actions: any) => {
        onConfirm(values, () => {
          onCancel()
        })
        actions.resetForm()
      }}
    >
      {(formikProps: any) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue
        } = formikProps

        return (
          <CommonModal
            header={header + " admin tip"}
            confirmText={confirmText}
            onConfirm={handleSubmit}
            onCancel={() => {
              onCancel()
              resetForm()
            }}
            ref={ref}
          >
            <Row>
            <Col md={12} className="mb-2">
                <RequiredLabel text="Tip" />
                <Editor
                  apiKey={TINY_MCE_KEY}
                  value={values.text}
                  init={{
                      ...editorConfig, 
                      paste_preprocess: function(_plugin: any, args: any) {
                          args.content = args.content.replace(/<\/?(?!b\b|p\b)[^>]+>/g, "")
                      }
                  }}
                  onEditorChange={content => {
                      setFieldValue(
                          "text",
                          content
                      )
                  }}
                />
                {touched.text && errors.text && (
                  <ErrorHandler text={errors.text as string} />
                )}
              </Col>
            </Row>
          </CommonModal>
        )
      }}
    </Formik>
  )
}

export default forwardRef(AdminTipDetailModal)