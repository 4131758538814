import { useCallback, useEffect, useState } from "react"
import { Filter } from "../../../types/Filter"
import { ClassLinkDomain } from "../../../types/ClassLinkDomain"
import { useDispatch } from "react-redux"
import { DEFAULT_FILTER } from "../../../utils/constants"
import { setAlert, setLoading, setModal } from "../../../redux/commons/action"
import { get, remove } from "../../../services/classLinkDomainService"

const useClassLinkDomainList = () => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER)
  const [data, setData] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  
  const changeFilter = (valueObject: any) => (
    setFilter({
      ...filter, 
      ...valueObject,
    })
  )

  const getData = useCallback(async () => {
    dispatch(setLoading(true))

    try {
      const res = await get(filter)
      const { totalItems, items } = res.data 
      setData(items)
      setTotalItems(totalItems)
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      )
    }
    dispatch(setLoading(false))
  }, [dispatch, filter])

  const removeData = useCallback(
    async (id: number) => {
      dispatch(
        setModal({
          isOpen: true,
          type: "warning",
          message: `Do you want to delete this classlink?`,
          onConfirm: async () => {
            dispatch(setLoading(true))

            try {
              await remove(id)
              changeFilter({ page: 1 })
              dispatch(
                setAlert({
                  type: "success",
                  message: "Delete classlink successfully",
                })
              )
            } catch (err: any) {
              dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data || err.message,
                })
              )
            }

            dispatch(setLoading(false))
          },
        })
      )
    }, [dispatch]
  )

  useEffect(() => {
    getData()
  }, [filter])

  return {
    data,
    totalItems,
    filter,
    changeFilter,
    removeData,
    getData,
  }

}

export default useClassLinkDomainList