import { api } from "./api"
import { Filter } from "../types/Filter"
import { ClassLinkDomain } from "../types/ClassLinkDomain"

const CLASSLINK_DOMAIN_URL = "/api/ClassLinkDomain"

export const get = (filter?: Filter) =>
  api.get(CLASSLINK_DOMAIN_URL, { params: filter })

export const getById = (id: number) => api.get(`${CLASSLINK_DOMAIN_URL}/${id}`)

export const create = (data: ClassLinkDomain) => api.post(CLASSLINK_DOMAIN_URL, data)

export const update = (data: ClassLinkDomain) =>
  api.put(`${CLASSLINK_DOMAIN_URL}/${data.id}`, data)

export const remove = (id: number) => api.delete(`${CLASSLINK_DOMAIN_URL}/${id}`)
